import { useQuery } from '@tanstack/vue-query';
import type { Database } from '~/types/Supabase';

export const useCreatorQuery = () => {
	const supabase = useSupabaseClient<Database>();
	const user = useSupabaseUser();

	const query = useQuery({
		queryKey: ['creator'],
		queryFn: async () => {
			if (!user.value) return null;

			const result = await supabase
				.from('creators')
				.select('*')
				.eq('user_id', user.value.id)
				.single();

			return result.data;
		},
		enabled: computed(() => !!user.value),
	});

	return query;
};
